import { customElement, attachShadowTemplate, coerceNumber, coreProperty, upgradeProperty } from '@tylertech/forge-core';
import { ViewSwitcherAdapter } from './view-switcher-adapter';
import { ViewSwitcherCore } from './view-switcher-core';
import { VIEW_SWITCHER_CONSTANTS, ViewSwitcherAnimationType } from './view-switcher-constants';
import { ViewComponent } from './view/view';
import { BaseComponent, IBaseComponent } from '../core/base/base-component';

const template = '<template><div class=\"forge-view-switcher\" part=\"root\"><slot></slot></div></template>';
const styles = '.forge-view-switcher{height:var(--forge-view-switcher-height,auto);width:var(--forge-view-switcher-width,auto);position:relative;overflow:hidden;-webkit-transition:height var(--forge-animation-duration-medium2, 300ms) cubic-bezier(.35, 0, .25, 1);transition:height var(--forge-animation-duration-medium2, 300ms) cubic-bezier(.35, 0, .25, 1)}.forge-view-switcher ::slotted(.forge-view-switcher__view--hidden){position:absolute;top:0;right:0;bottom:0;left:0}:host{height:var(--forge-view-switcher-height,auto);width:var(--forge-view-switcher-width,auto);display:block}:host([hidden]){display:none}:host(.forge-view-switcher--slide) ::slotted(forge-view){-webkit-transition:-webkit-transform var(--forge-animation-duration-medium2, 300ms) cubic-bezier(.35, 0, .25, 1);transition:-webkit-transform var(--forge-animation-duration-medium2, 300ms) cubic-bezier(.35, 0, .25, 1);transition:transform var(--forge-animation-duration-medium2, 300ms) cubic-bezier(.35, 0, .25, 1);transition:transform var(--forge-animation-duration-medium2, 300ms) cubic-bezier(.35, 0, .25, 1),-webkit-transform var(--forge-animation-duration-medium2, 300ms) cubic-bezier(.35, 0, .25, 1);will-change:transform}:host(.forge-view-switcher--fade) ::slotted(forge-view){-webkit-transition:opacity var(--forge-animation-duration-medium2, 300ms) cubic-bezier(.35, 0, .25, 1);transition:opacity var(--forge-animation-duration-medium2, 300ms) cubic-bezier(.35, 0, .25, 1);will-change:opacity}:host(.forge-view-switcher--fade) ::slotted(.forge-view-switcher__view--hidden){opacity:0}';

export interface IViewSwitcherComponent extends IBaseComponent {
  index: number;
  animationType: `${ViewSwitcherAnimationType}`;
  next(): void;
  previous(): void;
  goToStart(): void;
  goToEnd(): void;
}

declare global {
  interface HTMLElementTagNameMap {
    'forge-view-switcher': IViewSwitcherComponent;
  }
}

/**
 * @tag forge-view-switcher
 */
@customElement({
  name: VIEW_SWITCHER_CONSTANTS.elementName,
  dependencies: [ViewComponent]
})
export class ViewSwitcherComponent extends BaseComponent implements IViewSwitcherComponent {
  public static get observedAttributes(): string[] {
    return [VIEW_SWITCHER_CONSTANTS.attributes.INDEX, VIEW_SWITCHER_CONSTANTS.attributes.ANIMATION_TYPE];
  }

  private _core: ViewSwitcherCore;

  constructor() {
    super();
    attachShadowTemplate(this, template, styles);
    this._core = new ViewSwitcherCore(new ViewSwitcherAdapter(this));
  }

  public connectedCallback(): void {
    // upgradeProperty(this, 'index');
    this._core.initialize();
  }

  public disconnectedCallback(): void {
    this._core.disconnect();
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    switch (name) {
      case VIEW_SWITCHER_CONSTANTS.attributes.INDEX:
        this.index = coerceNumber(newValue);
        break;
      case VIEW_SWITCHER_CONSTANTS.attributes.ANIMATION_TYPE:
        this.animationType = newValue as ViewSwitcherAnimationType;
        break;
    }
  }

  /** Gets/sets the currently visible view index. */
  @coreProperty()
  public declare index: number;

  /** Gets/sets the animation type. */
  @coreProperty()
  public declare animationType: `${ViewSwitcherAnimationType}`;

  /** Transitions to the next view. */
  public next(): void {
    this._core.next();
  }

  /** Transitions to the previous view. */
  public previous(): void {
    this._core.previous();
  }

  /** Transitions to the first view. */
  public goToStart(): void {
    this._core.goToStart();
  }

  /** Transitions to the last view. */
  public goToEnd(): void {
    this._core.goToEnd();
  }
}
