import { customElement, attachShadowTemplate, coerceBoolean } from '@tylertech/forge-core';
import { BaseComponent, IBaseComponent } from '../core/base/base-component';
import { CARD_CONSTANTS } from './card-constants';

const template = '<template><div class=\"forge-card\" part=\"root\"><slot></slot></div></template>';
const styles = ':host{display:block}:host([hidden]){display:none}.forge-card{--_card-background:var(--forge-card-background, var(--forge-theme-surface, #ffffff));--_card-height:var(--forge-card-height, 100%);--_card-width:var(--forge-card-width, 100%);--_card-outline-color:var(--forge-card-outline-color, var(--forge-theme-outline, #e0e0e0));--_card-outline-width:var(--forge-card-outline-width, var(--forge-border-thin, 1px));--_card-outline-style:var(--forge-card-outline-style, solid);--_card-elevation:var(--forge-card-elevation, none);--_card-padding:var(--forge-card-padding, var(--forge-spacing-medium, 16px));--_card-shape:var(--forge-card-shape, var(--forge-shape-medium, 4px));--_card-overflow:var(--forge-card-overflow, hidden);--_card-raised-elevation:var(--forge-card-raised-elevation, 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12));--_card-raised-outline-width:var(--forge-card-raised-outline-width, 0)}.forge-card{background:var(--_card-background);border-color:var(--_card-outline-color);border-width:var(--_card-outline-width);border-style:var(--_card-outline-style);border-radius:var(--_card-shape);-webkit-box-shadow:var(--_card-elevation);box-shadow:var(--_card-elevation);-webkit-box-sizing:border-box;box-sizing:border-box;padding:var(--_card-padding);overflow:var(--_card-overflow);height:var(--_card-height);width:var(--_card-width);-webkit-tap-highlight-color:transparent}:host([raised]) .forge-card{--_card-elevation:var(--_card-raised-elevation);--_card-outline-width:var(--_card-raised-outline-width)}:host([no-padding]) .forge-card{--_card-padding:var(--forge-card-padding, 0)}';

export interface ICardComponent extends IBaseComponent {
  raised: boolean;
}

declare global {
  interface HTMLElementTagNameMap {
    'forge-card': ICardComponent;
  }
}

/**
 * @tag forge-card
 *
 * @summary Cards are used to group related information and actions about a single subject.
 *
 * @property {boolean} [raised=false] - Whether the card has elevation or not.
 *
 * @attribute {boolean} [raised=false] - Whether the card has an raised or not.
 * @attribute {boolean} no-padding - Removes the default padding from the card.
 *
 * @cssproperty --forge-card-background - The background color of the card.
 * @cssproperty --forge-card-height - The height of the card.
 * @cssproperty --forge-card-width - The width of the card.
 * @cssproperty --forge-card-outline-color - The outline color of the card.
 * @cssproperty --forge-card-outline-width - The outline width of the card.
 * @cssproperty --forge-card-outline-style - The outline style of the card.
 * @cssproperty --forge-card-elevation - The elevation/shadow of the card.
 * @cssproperty --forge-card-padding - The padding of the card.
 * @cssproperty --forge-card-shape - The shape (border-radius) of the card.
 * @cssproperty --forge-card-overflow - The overflow of the card.
 * @cssproperty --forge-card-raised-elevation - The elevation/shadow of the card when raised.
 * @cssproperty --forge-card-raised-outline-width - The outline width of the card when raised.
 *
 * @csspart root - The root container element.
 */
@customElement({
  name: CARD_CONSTANTS.elementName
})
export class CardComponent extends BaseComponent implements ICardComponent {
  public static get observedAttributes(): string[] {
    return Object.values(CARD_CONSTANTS.observedAttributes);
  }

  private _raised = false;

  constructor() {
    super();
    attachShadowTemplate(this, template, styles);
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    switch (name) {
      case CARD_CONSTANTS.attributes.RAISED:
        this.raised = coerceBoolean(newValue);
        break;
    }
  }

  public get raised(): boolean {
    return this._raised;
  }
  public set raised(value: boolean) {
    value = Boolean(value);
    if (this._raised !== value) {
      this._raised = value;
      this.toggleAttribute(CARD_CONSTANTS.attributes.RAISED, this._raised);
    }
  }
}
