import { attachShadowTemplate, coerceBoolean, customElement, coreProperty } from '@tylertech/forge-core';
import { BaseComponent, IBaseComponent } from '../core/base/base-component';
import { ExpansionPanelAdapter } from './expansion-panel-adapter';
import { ExpansionPanelAnimationType, ExpansionPanelOrientation, EXPANSION_PANEL_CONSTANTS } from './expansion-panel-constants';
import { ExpansionPanelCore } from './expansion-panel-core';

const template = '<template><div class=\"forge-expansion-panel\" part=\"root\"><div class=\"header\" part=\"header\"><slot name=\"header\"></slot></div><div class=\"content hidden\" part=\"content\"><div class=\"inner\"><slot></slot></div></div></div></template>';
const styles = ':host{display:block}:host([hidden]){display:none}.forge-expansion-panel{--_expansion-panel-animation-duration:var(--forge-expansion-panel-animation-duration, var(--forge-animation-duration-medium4, 400ms));--_expansion-panel-animation-easing:var(--forge-expansion-panel-animation-easing, var(--forge-animation-easing-standard, cubic-bezier(0.2, 0, 0, 1)))}.header{outline:0;-webkit-tap-highlight-color:transparent;cursor:pointer}.content{display:grid;grid-template-rows:0fr;-webkit-transition-property:opacity,grid-template-rows;transition-property:opacity,grid-template-rows;transition-property:opacity,grid-template-rows,-ms-grid-rows;-webkit-transition-duration:var(--_expansion-panel-animation-duration);transition-duration:var(--_expansion-panel-animation-duration);-webkit-transition-timing-function:var(--_expansion-panel-animation-easing);transition-timing-function:var(--_expansion-panel-animation-easing);min-height:0;overflow:hidden;opacity:0}.content .inner{display:grid;grid-template-rows:1fr;overflow:hidden}.hidden{visibility:hidden}@media (prefers-reduced-motion:reduce){.content{-webkit-transition:none;transition:none}}:host([open]) .content{opacity:1}:host([open]:is(:not([orientation]),[orientation=vertical])) .content{grid-template-rows:1fr}:host([open][orientation=horizontal]) .content{grid-template-columns:1fr}:host([orientation=horizontal]) .forge-expansion-panel{display:grid;grid-template-columns:auto 1fr}:host([orientation=horizontal]) .content{-webkit-transition-property:opacity,grid-template-columns;transition-property:opacity,grid-template-columns;transition-property:opacity,grid-template-columns,-ms-grid-columns;grid-template-rows:none;grid-template-columns:0fr}:host([animation-type=none]) .content{-webkit-transition:none;transition:none}';

export interface IExpansionPanelComponent extends IBaseComponent {
  open: boolean;
  orientation: ExpansionPanelOrientation;
  animationType: ExpansionPanelAnimationType;
  toggle(): void;
}

declare global {
  interface HTMLElementTagNameMap {
    'forge-expansion-panel': IExpansionPanelComponent;
  }

  interface HTMLElementEventMap {
    'forge-expansion-panel-toggle': CustomEvent<boolean>;
    'forge-expansion-panel-animation-complete': CustomEvent<boolean>;
  }
}

/**
 * @tag forge-expansion-panel
 *
 * @summary Expansion panels provide progressive disclosure of content.
 *
 * @property {boolean} [open=false] - Whether the panel is open or closed.
 * @property {ExpansionPanelOrientation} [orientation="vertical"] - The orientation of the panel.
 * @property {ExpansionPanelAnimationType} [animationType="default"] - The type of animation to use when opening/closing the panel.
 *
 * @attribute {boolean} [open=false] - Whether the panel is open or closed.
 * @attribute {ExpansionPanelOrientation} [orientation="vertical"] - The orientation of the panel.
 * @attribute {ExpansionPanelAnimationType} [animation-type="default"] - The type of animation to use when opening/closing the panel.
 *
 * @fires {CustomEvent<boolean>} forge-expansion-panel-toggle - Event fired when the panel is toggled open or closed.
 * @fires {CustomEvent<boolean>} forge-expansion-panel-animation-complete - Event fired when the panel has finished animating when toggling.
 *
 * @cssproperty --forge-expansion-panel-animation-duration - The duration of the open/close animation.
 * @cssproperty --forge-expansion-panel-animation-easing - The easing function of the open/close animation.
 *
 * @csspart root - The root element of the panel.
 * @csspart header - The header of the panel.
 * @csspart content - The content of the panel.
 *
 * @slot - The content of the panel.
 * @slot header - The header of the panel.
 */
@customElement({
  name: EXPANSION_PANEL_CONSTANTS.elementName
})
export class ExpansionPanelComponent extends BaseComponent implements IExpansionPanelComponent {
  public static get observedAttributes(): string[] {
    return Object.values(EXPANSION_PANEL_CONSTANTS.observedAttributes);
  }

  private _core: ExpansionPanelCore;

  constructor() {
    super();
    attachShadowTemplate(this, template, styles);
    this._core = new ExpansionPanelCore(new ExpansionPanelAdapter(this));
  }

  public connectedCallback(): void {
    this._core.initialize();
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    switch (name) {
      case EXPANSION_PANEL_CONSTANTS.observedAttributes.OPEN:
        this.open = coerceBoolean(newValue);
        break;
      case EXPANSION_PANEL_CONSTANTS.observedAttributes.ORIENTATION:
        this.orientation = newValue as ExpansionPanelOrientation;
        break;
      case EXPANSION_PANEL_CONSTANTS.observedAttributes.ANIMATION_TYPE:
        this.animationType = newValue as ExpansionPanelAnimationType;
        break;
    }
  }

  @coreProperty()
  public declare open: boolean;

  @coreProperty()
  public declare orientation: ExpansionPanelOrientation;

  @coreProperty()
  public declare animationType: ExpansionPanelAnimationType;

  /**
   * Toggles the open state of the panel.
   */
  public toggle(): void {
    this.open = !this.open;
  }
}
