import { attachShadowTemplate, coerceBoolean, customElement } from '@tylertech/forge-core';
import { BaseComponent, IBaseComponent } from '../core/base/base-component';
import { DIVIDER_CONSTANTS } from './divider-constants';

const template = '<template><div class=\"forge-divider\" part=\"root\"></div></template>';
const styles = ':host{--_divider-margin:var(--forge-divider-margin, 0)}:host{display:block;contain:content;margin:var(--_divider-margin)}:host([hidden]){display:none}.forge-divider{--_divider-color:var(--forge-divider-color, var(--forge-theme-outline, #e0e0e0));--_divider-width:var(--forge-divider-width, var(--forge-border-thin, 1px));--_divider-border-style:var(--forge-divider-border-style, solid)}.forge-divider{border:none;border-bottom-color:var(--_divider-color);border-bottom-width:var(--_divider-width);border-bottom-style:var(--_divider-border-style);height:0}:host([vertical]){display:inline-block;height:100%}:host([vertical]) .forge-divider{border:none;border-right-color:var(--_divider-color);border-right-width:var(--_divider-width);border-right-style:var(--_divider-border-style);height:100%;width:0}';

export interface IDividerComponent extends IBaseComponent {
  vertical: boolean;
}

declare global {
  interface HTMLElementTagNameMap {
    'forge-divider': IDividerComponent;
  }
}

/**
 * @tag forge-divider
 *
 * @summary Divider is used to separate elements.
 *
 * @property {boolean} vertical - Controls if the divider is displayed vertically or horizontally.
 *
 * @attribute {boolean} vertical - Controls if the divider is displayed vertically or horizontally.
 *
 * @cssproperty --forge-divider-color - The color of the divider.
 * @cssproperty --forge-divider-width - The width of the divider.
 * @cssproperty --forge-divider-border-style - The border-style (dashed, solid) of the divider.
 * @cssproperty --forge-divider-margin - The margin of divider.
 *
 * @csspart root - The root container element.
 */
@customElement({
  name: DIVIDER_CONSTANTS.elementName
})
export class DividerComponent extends BaseComponent implements IDividerComponent {
  public static get observedAttributes(): string[] {
    return [DIVIDER_CONSTANTS.attributes.VERTICAL];
  }

  constructor() {
    super();
    attachShadowTemplate(this, template, styles);
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    switch (name) {
      case DIVIDER_CONSTANTS.attributes.VERTICAL:
        this.vertical = coerceBoolean(newValue);
        break;
    }
  }

  public get vertical(): boolean {
    return this.hasAttribute(DIVIDER_CONSTANTS.attributes.VERTICAL);
  }

  public set vertical(value: boolean) {
    this.toggleAttribute(DIVIDER_CONSTANTS.attributes.VERTICAL, value);
  }
}
