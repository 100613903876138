import { customElement, attachShadowTemplate } from '@tylertech/forge-core';
import { BaseComponent, IBaseComponent } from '../../core/base/base-component';

import { VIEW_CONSTANTS } from './view-constants';

const template = '<template><slot></slot></template>';
const styles = ':host{display:block}:host([hidden]){display:none}';

export interface IViewComponent extends IBaseComponent {}

declare global {
  interface HTMLElementTagNameMap {
    'forge-view': IViewComponent;
  }
}

/**
 * @tag forge-view
 */
@customElement({
  name: VIEW_CONSTANTS.elementName
})
export class ViewComponent extends BaseComponent implements IViewComponent {
  constructor() {
    super();
    attachShadowTemplate(this, template, styles);
  }
}
