import { customElement, attachShadowTemplate } from '@tylertech/forge-core';
import { BaseComponent, IBaseComponent } from '../core/base/base-component';
import { SCAFFOLD_CONSTANTS } from './scaffold-constants';

const template = '<template><div class=\"forge-scaffold\" part=\"root\"><slot name=\"left\"></slot><div class=\"header\" part=\"header\"><slot name=\"header\"></slot></div><div class=\"body\" part=\"body\"><slot name=\"body-left\"></slot><slot name=\"body-header\"></slot><slot name=\"body\"></slot><slot name=\"body-footer\"></slot><slot name=\"body-right\"></slot></div><slot name=\"footer\"></slot><slot name=\"right\"></slot></div></template>';
const styles = ':host{--_scaffold-height:var(--forge-scaffold-height, 100%);--_scaffold-width:var(--forge-scaffold-width, 100%)}:host{display:block;width:var(--_scaffold-width);height:var(--_scaffold-height)}:host([hidden]){display:none}.forge-scaffold{--_scaffold-overflow:var(--forge-scaffold-overflow, hidden);--_scaffold-body-position:var(--forge-scaffold-body-position, relative)}.forge-scaffold{position:relative;display:grid;grid-template-areas:\"left header right\" \"left body right\" \"left footer right\";grid-template-rows:auto 1fr auto;grid-template-columns:auto 1fr auto;height:var(--_scaffold-height);width:var(--_scaffold-width);overflow:var(--_scaffold-overflow)}.header{grid-area:header;min-width:0;min-height:0}.body{position:var(--_scaffold-body-position);display:grid;grid-area:body;grid-template-areas:\"body-left body-header body-right\" \"body-left body-inner body-right\" \"body-left body-footer body-right\";grid-template-rows:auto 1fr auto;grid-template-columns:auto 1fr auto;width:var(--_scaffold-width);min-width:0;min-height:0;overflow:hidden}::slotted(*){min-width:0;min-height:0}::slotted([slot=left]){grid-area:left}::slotted([slot=right]){grid-area:right}::slotted([slot=body-left]){grid-area:body-left}::slotted([slot=body-right]){grid-area:body-right}::slotted([slot=body-header]){grid-area:body-header}::slotted([slot=body]){overflow:auto;grid-area:body-inner}::slotted([slot=body-footer]){grid-area:body-footer}::slotted([slot=footer]){grid-area:footer}::slotted([slot=body-left]),::slotted([slot=body-right]),::slotted([slot=body]),::slotted([slot=left]),::slotted([slot=right]){overflow:auto}:host([viewport]){--_scaffold-height:var(--forge-scaffold-height, 100dvh);--_scaffold-width:var(--forge-scaffold-width, 100dvw)}';

export interface IScaffoldComponent extends IBaseComponent {
  viewport: boolean;
}

declare global {
  interface HTMLElementTagNameMap {
    'forge-scaffold': IScaffoldComponent;
  }
}

/**
 * @tag forge-scaffold
 *
 * @summary A scaffold provides a generic layout structure for your content using common named areas.
 *
 * @property {boolean} [viewport=false] - Whether the scaffold should be full viewport height.
 *
 * @attribute {boolean} [viewport=false] - Whether the scaffold should be full viewport height.
 *
 * @cssproperty --forge-scaffold-height - The `height` of the scaffold.
 * @cssproperty --forge-scaffold-width - The `width` of the scaffold.
 * @cssproperty --forge-scaffold-overflow - The `overflow` of the scaffold.
 * @cssproperty --forge-scaffold-body-position - The `position` of the scaffold body.
 *
 * @csspart root - The root container element.
 * @csspart header - The header of the scaffold.
 * @csspart body - The body of the scaffold.
 *
 * @slot header - Places content in the header.
 * @slot body - Places content in the body.
 * @slot footer - Places content in the footer.
 * @slot left - Places content to the left of all content.
 * @slot right - Places content to the right of all content.
 * @slot body-header - Places content in the header of the body.
 * @slot body-footer - Places content in the footer of the body.
 * @slot body-left - Places content to the left of the body content.
 * @slot body-right - Places content to the right of the body content.
 */
@customElement({
  name: SCAFFOLD_CONSTANTS.elementName
})
export class ScaffoldComponent extends BaseComponent implements IScaffoldComponent {
  public static get observedAttributes(): string[] {
    return Object.values(SCAFFOLD_CONSTANTS.observedAttributes);
  }

  constructor() {
    super();
    attachShadowTemplate(this, template, styles);
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    switch (name) {
      case SCAFFOLD_CONSTANTS.observedAttributes.VIEWPORT:
        this.viewport = newValue !== null;
        break;
    }
  }

  public get viewport(): boolean {
    return this.hasAttribute(SCAFFOLD_CONSTANTS.attributes.VIEWPORT);
  }
  public set viewport(value: boolean) {
    this.toggleAttribute(SCAFFOLD_CONSTANTS.attributes.VIEWPORT, Boolean(value));
  }
}
